import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

import communifyApi from 'Configs/Api';
import {
  removeMeeting,
  updateMeeting,
} from 'Pages/Dashboard/UpcomingMeetings/UpcomingMeetingsSlice';

export const useFetch = ({ method = 'get', url, data = null, config = null }) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const code = useQuery().get('code');
  const state = useQuery().get('state');
  const dispatch = useDispatch();
  const history = useHistory('/');

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      try {
        if (mounted) setIsLoading(() => true);
        const res = await communifyApi[method](url, JSON.parse(config), JSON.parse(data));
        if (res?.data?.messageKey === 'TeamsAppNotAccess') {
          history.push('/not-found');
        }
        if (res.data.hasError === false && mounted) {
          setResponse(res.data.data);
          setMessage(res.data.message);
        } else {
          throw new Error(res.data.message);
        }
      } catch (err) {
        if (mounted) setError(err);
      } finally {
        if (mounted) setIsLoading(() => false);
      }
    };
    fetchData();
    return () => (mounted = false);
  }, [method, url, data, config, code, state, dispatch, history]);

  return { response, error, isLoading, message };
};

// A custom hook that builds on useLocation to parse
// the query string.
export const useQuery = () => new URLSearchParams(useLocation().search);

export const useFetchUpcomingMeetings = endpointType => {
  const { response, isLoading } = useFetch({
    url: '/DashBoard/GetUpComingMeetings', // TODO; Make dashboard resuable
    config: JSON.stringify({
      params: {
        ItemCount: 4,
        date: getNativeDate(),
        endpointType,
      },
    }),
  });

  return { response, isLoading };
};

export const getNativeDate = () => moment().format('YYYY-MM-DD');

export const useCheckInTimeoutLimit = meetings => {
  const dispatch = useDispatch();
  const timer = useRef(0);

  const meetingsRealTimeChange = useCallback(
    (elem, index) => {
      if (elem.roomReleaseAfterMeetingStart === null) {
        return;
      }

      if (
        elem.afterOrBeforeMeetingStart + timer.current >= elem.roomReleaseAfterMeetingStart &&
        elem.attendeeType === 1
      ) {
        dispatch(updateMeeting({ index, timer: timer.current }));
      } else if (
        elem.afterOrBeforeMeetingStart + timer.current >
        elem.roomReleaseAfterMeetingStart
      ) {
        dispatch(removeMeeting(index));
      }
      return elem;
    },
    [dispatch],
  );

  const startTimeoutLimit = useCallback(() => {
    if (meetings?.[0]?.roomReleaseAfterMeetingStart === 0 && timer.current === 0) {
      timer.current = timer.current + 1;
      meetings.map((elem, index) => meetingsRealTimeChange(elem, index));
    }

    const interval = setInterval(() => {
      timer.current = timer.current + 1;
      if (meetings.length > 0) meetings.map((elem, index) => meetingsRealTimeChange(elem, index));
    }, 60000);

    return () => clearInterval(interval);
  }, [meetings, meetingsRealTimeChange, timer]);

  useEffect(() => {
    const currentSec = parseInt(moment().format('ss'));

    if (timer.current === 0 && meetings) {
      meetings.map((elem, index) => meetingsRealTimeChange(elem, index));
    }

    const timeout = setTimeout(() => {
      startTimeoutLimit();
    }, (60 - currentSec) * 1000);

    return () => clearTimeout(timeout);
  }, [meetings, meetingsRealTimeChange, timer, startTimeoutLimit]);
};

export const useRealtimeUpdate = (cb, data, type) => {
  const [additionalSeconds, setAdditionalSeconds] = useState(parseInt(moment().format('s')));
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      type === 'global' ? dispatch(cb(data)) : cb(data);
      setAdditionalSeconds(() => 0);
    }, (60 - additionalSeconds) * 1000);

    return () => clearInterval(interval);
  }, [additionalSeconds, cb, data, type, dispatch]);
};

export const useMount = effect => useEffect(effect, []);

export function useDebounce(value, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
}
