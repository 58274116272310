import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import { WithSuspense } from 'Utils/HOC/index.js';

const FAQ = WithSuspense(lazy(() => import('Pages/FAQ/FAQ')));
const Dashboard = WithSuspense(lazy(() => import('Pages/Dashboard')));
const MyMeetings = WithSuspense(lazy(() => import('Pages/MyMeetings')));
const NotFound = WithSuspense(lazy(() => import('Components/404/NotFound')));
const LogoutPage = WithSuspense(lazy(() => import('Components/LogOutComponent/LogoutPage')));
const StartAuth = WithSuspense(lazy(() => import('Components/StartAuth/StartAuth')));

const RoomFinderFilter = WithSuspense(() =>
  import('Pages/Dashboard/Flyout/RoomFinderFilter/RoomFinderFilter'),
);

export const routersConfig = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
  {
    path: '/dashboard',
    component: Dashboard,
  },
  {
    path: '/dashboard/suggested-rooms',
    component: Dashboard,
  },
  {
    path: '/dashboard/new-meeting',
    component: Dashboard,
  },
  {
    path: '/dashboard/edit/:meeting',
    component: Dashboard,
  },
  {
    path: '/dashboard/edit/:meeting/set-attendee-type',
    component: Dashboard,
  },
  {
    path: '/my-meetings',
    component: MyMeetings,
  },
  {
    path: '/faq',
    component: FAQ,
  },
  {
    path: '/room-finder/filters',
    component: RoomFinderFilter,
  },
  {
    path: '/logout',
    component: () => <LogoutPage title="See you again soon." body="You have been signed out." />,
  },
  {
    path: '/signin',
    component: () => (
      <LogoutPage title="Welcome" body="Reduce workplace friction. Get business back to work." />
    ),
  },
  {
    path: '/checkin',
    component: LogoutPage,
  },
  {
    path: '/start-auth',
    component: StartAuth,
  },
  // 404
  {
    path: '*',
    exact: true,
    component: NotFound,
  },
];
