import axios from 'axios';

import { refreshAccessToken } from 'Utils/utils';

const communifyApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || 'https://teamsapp.api.dev.communifyapp.com/',
  // https://github.com/axios/axios#creating-an-instance
});

// Set the auth token for any request
communifyApi.interceptors.request.use(config => {
  config.headers.Authorization = localStorage.tokenData
    ? `Bearer ${JSON.parse(localStorage.tokenData).accessToken}`
    : '';
  return config;
});

// Response interceptor for API calls
communifyApi.interceptors.response.use(
  async response => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      if (localStorage.tokenData) {
        if (JSON.parse(localStorage.tokenData).hasTeamsAcess === false) {
          window.location.href = '/not-found';
        }
      }
      if (
        !new URLSearchParams(window.location.search).get('code') &&
        !new URLSearchParams(window.location.search).get('state') &&
        !localStorage.tokenData
      ) {
        if (!window.location.pathname.includes('logout')) {
          window.location.href = '/signin';
        }
      } else if (
        !originalRequest._retry &&
        localStorage.tokenData &&
        !localStorage.tokenData.includes('null')
      ) {
        const access_token = await refreshAccessToken();
        communifyApi.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        originalRequest._retry = true;
        return communifyApi(originalRequest);
      }
    }
    return Promise.reject(error);
  },
);

export default communifyApi;
