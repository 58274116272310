import { createSlice } from '@reduxjs/toolkit';

const searchSlice = createSlice({
  name: 'searchFields',
  initialState: {
    fields: null,
    term: '',
    selectedTab: 'room',
  },
  reducers: {
    setFields(state, action) {
      state.fields = action.payload;
    },
    setTerm(state, action) {
      state.term = action.payload;
    },
    setSelectedTab(state, action) {
      state.selectedTab = action.payload;
    },
  },
});

export const { setFields, setTerm, setSelectedTab } = searchSlice.actions;

export default searchSlice.reducer;
