import { createSlice } from '@reduxjs/toolkit';

const UpcomingMeetingsSlice = createSlice({
  name: 'UpcomingMeetings',
  initialState: {
    meetings: null,
  },
  reducers: {
    setMeetings(state, action) {
      state.meetings = action.payload;
    },
    removeMeeting(state, action) {
      const { meetings } = state;
      if (meetings) {
        meetings.splice(action.payload, 1);
      } else {
        return meetings;
      }
    },
    updateMeeting(state, action) {
      state.meetings[action.payload.index].timer = action.payload?.timer;
    },
  },
});

export const { setMeetings, removeMeeting, updateMeeting } = UpcomingMeetingsSlice.actions;

export default UpcomingMeetingsSlice.reducer;
