import { createSlice } from '@reduxjs/toolkit';

const DashboardSlice = createSlice({
  name: 'Dashboard',
  initialState: {
    tokenData: {},
    isGlobalLoading: true,
    isDiscardChanged: false,
  },
  reducers: {
    setToken(state, action) {
      state.tokenData = action.payload;
    },
    setGlobalLoading(state, action) {
      state.isGlobalLoading = action.payload;
    },
    setDiscardChanged(state, action) {
      state.isDiscardChanged = action.payload;
    },
  },
});

export const { setToken, setGlobalLoading, setDiscardChanged } = DashboardSlice.actions;

export default DashboardSlice.reducer;
