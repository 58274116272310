import { createSlice } from '@reduxjs/toolkit';

const flyoutSlice = createSlice({
  name: 'flyout',
  initialState: {
    flyoutTransitionClassName: null,
  },
  reducers: {
    setflyoutTransitionClassName(state, action) {
      state.flyoutTransitionClassName = action.payload;
    },
  },
});

export const { setflyoutTransitionClassName } = flyoutSlice.actions;

export default flyoutSlice.reducer;
