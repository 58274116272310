import { createSlice } from '@reduxjs/toolkit';

const roomsSlice = createSlice({
  name: 'suggestedRooms',
  initialState: {
    suggestedRooms: null,
  },
  reducers: {
    getRooms(state, action) {
      state.suggestedRooms = action.payload;
    },
  },
});

export const { getRooms } = roomsSlice.actions;

export default roomsSlice.reducer;
