import React from 'react';
import { Switch } from 'react-router-dom';

import { routersConfig } from 'Configs/Routes';
import SubRouting from 'Configs/Routes/SubRouting';

// Modules
// import './Utils/CustomModules/TouchReloadPrevention';

// react dates
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

// Slider
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './index.scss';

const App = () => (
  <Switch>
    {routersConfig.map((route, i) => (
      <SubRouting key={i} {...route} />
    ))}
  </Switch>
);

export default App;
