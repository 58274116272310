import { store } from 'index';
import { setMeetings } from './UpcomingMeetingsSlice';
import { request } from 'Utils/utils';
import { getNativeDate } from 'Utils/hooks';

export const getMeetings = async (
  route = 'GetUpComingMeetings',
  itemCount = 4,
  customDate = null,
) => {
  const { response: res } = await request({
    url: `/DashBoard/${route}`, // TODO; Make dashboard resuable
    config: JSON.stringify({
      params: {
        itemCount,
        date: customDate || getNativeDate(),
      },
    }),
  });
  if (res) {
    store.dispatch(setMeetings(res));
  }
};
