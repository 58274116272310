import { createSlice } from '@reduxjs/toolkit';

const myMeetingsSlice = createSlice({
  name: 'myMeetings',
  initialState: {
    meetingItems: null,
  },
  reducers: {},
});

export const { setMeetingItems } = myMeetingsSlice.actions;

export default myMeetingsSlice.reducer;
