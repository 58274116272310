import { createSlice } from '@reduxjs/toolkit';

const roomFinderSlice = createSlice({
  name: 'roomFinder',
  initialState: {
    buildings: null,
    featureMobile: [],
    FeaturesId: [],
    FloorId: [],
    floorsMobile: [],
    isWheelChairAccesisible: 'null',
    roomRequest: false,
  },
  reducers: {
    setBuildings(state, action) {
      state.buildings = action.payload;
    },
    setFloors(state, action) {
      state.floors = action.payload;
    },
    setFeatures(state, action) {
      state.features = action.payload;
    },
    setFeaturesMobile(state, action) {
      state.featureMobile = action.payload;
    },
    setFloorsMobile(state, action) {
      state.floorsMobile = action.payload;
    },
    handleChange(state, action) {
      state[action.payload.name] = action.payload.value;
    },
  },
});

export const {
  setBuildings,
  setFloors,
  setFeatures,
  setFeaturesMobile,
  setFloorsMobile,
  handleChange,
} = roomFinderSlice.actions;

export default roomFinderSlice.reducer;
