import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import * as microsoftTeams from '@microsoft/teams-js';
import { BrowserRouter as Router } from 'react-router-dom';
// ... other imports/requires

import App from './App';
import rootReducer from './Reducers';
import { useDiscardCheck } from './Utils/utils';

export const store = configureStore({
  reducer: rootReducer,
});

useDiscardCheck();

microsoftTeams.initialize();

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root'),
);
