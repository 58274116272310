import { createSlice } from '@reduxjs/toolkit';
import { defaultTime } from './StartEndDate/GenerateTimes';
import moment from 'moment';

const initialState = {
  title: '',
  isTeamsMeeting: true,
  privateMeeting: false,
  allDay: false,
  attendees: [],
  bodyForMeeting: '',
  requestMeetings: true,
  attendeesInputValue: '',
  optionalAttendeesInputValue: '',
  locationValue: '',
  date: {
    startTime: {
      value: defaultTime().startTime.value,
      label: defaultTime().startTime.label,
    },
    endTime: {
      value: defaultTime().endTime.value,
      label: defaultTime().endTime.label,
    },
  },
  repeat: 'no-repeat',
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  filterOptional: '',
  filter: '',
  attendeesList: [],
  filteredAttendeesList: [],
  locations: [],
  pattern: {
    type: 0,
    interval: 1,
    daysOfWeek: [1],
  },
  range: {
    numberOfOccurrences: 1,
    type: 'never',
    endDate: moment().format('YYYY-MM-DD'),
  },
};

const newMeetingSlice = createSlice({
  name: 'newMeeting',
  initialState: initialState,
  reducers: {
    handleSetStore() {
      return initialState;
    },
    handleChangeStore(state, action) {
      state[action.payload.name] = action.payload.value;
    },
    handleChangePattern(state, action) {
      state.pattern = {
        ...state.pattern,
        [action.payload.name]: action.payload.value,
      };
    },
    handleChangeRange(state, action) {
      state.range = {
        ...state.range,
        [action.payload.name]: action.payload.value,
      };
    },
    handleChangeEditMeeting(state, action) {
      state.locations = action.payload.locations;
      state.title = action.payload.title;
      state.date = action.payload.date;
      state.endDate = action.payload.endDate;
      state.startDate = action.payload.startDate;
      state.attendees = action.payload.attendees;
      state.oldStartDate = action.payload.oldStartDate;
      state.privateMeeting = action.payload.privateMeeting;
      state.isTeamsMeeting = action.payload.isTeamsMeeting;
      state.allDay = action.payload.allDay;
      state.seriesMzasterId = action.payload.seriesMasterId;
      state.pattern = action.payload.pattern;
      state.range = action.payload.range;
      state.bodyForMeeting = action.payload.bodyForMeeting;
    },
  },
});

export const {
  handleChangeStore,
  handleChangePattern,
  handleChangeRange,
  handleChangeEditMeeting,
  handleSetStore,
} = newMeetingSlice.actions;

export default newMeetingSlice.reducer;
