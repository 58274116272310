import moment from 'moment';

function round(date, duration, method) {
  return moment(Math[method](+date / +duration) * +duration);
}

export const defaultTime = () => {
  const date = moment();

  const roundedDate = round(date, moment.duration(15, 'minutes'), 'ceil');

  const locationCHeck = window.location.pathname.includes('my-meetings')
    ? moment(roundedDate)
    : moment();

  const startTime = {
    value: locationCHeck.format('hh:mm'),
    label: locationCHeck.format('h:mm A'),
  };
  const endCheck = locationCHeck.add({ minute: 30 });
  const endTime = {
    value: endCheck.format('hh:mm'),
    label: endCheck.format('h:mm A'),
  };
  return { startTime, endTime };
};
