import { createSlice } from '@reduxjs/toolkit';

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    snackbarMessage: null,
  },
  reducers: {
    setSnackbarMessage(state, action) {
      state.message = action.payload;
    },
  },
});

export const { setSnackbarMessage } = snackbarSlice.actions;

export default snackbarSlice.reducer;
