import { combineReducers } from 'redux';

import FlyoutReducer from 'Components/Flyout/FlyoutSlice';
import DashboardReducer from 'Pages/Dashboard/DashboardSlice';
import meetingsReducer from 'Pages/MyMeetings/MyMeetingsSlice';
import searchFieldsReducer from 'Pages/Dashboard/Search/SearchSlice';
import snackbarMessageReducer from 'Components/Snackbar/snackbarSlice';
import roomsReducer from 'Pages/Dashboard/SuggestedRooms/suggestedRoomsSlice';
import newMeetingSlice from 'Pages/Dashboard/Flyout/NewMeeting/NewMeetingSlice';
import roomFinderReducer from 'Pages/Dashboard/Flyout/RoomFinder/RoomFinderSlice';
import UpcomingMeetngs from 'Pages/Dashboard/UpcomingMeetings/UpcomingMeetingsSlice';

export default combineReducers({
  rooms: roomsReducer,
  flyout: FlyoutReducer,
  newMeeting: newMeetingSlice,
  myMeetings: meetingsReducer,
  dashboard: DashboardReducer,
  roomFinder: roomFinderReducer,
  upcomingMeetngs: UpcomingMeetngs,
  searchFields: searchFieldsReducer,
  snackbarMessage: snackbarMessageReducer,
});
